.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 2px;

  :global(.dd-table) {
    height: calc(100% - 60px) !important;
  }
}

:global(.ant-modal) {
  width:800px !important;
}

